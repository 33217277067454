<template>
  <div class="manager-container">
    <div class="left" v-if="hasSysAdminPermission">
      <div class="title">
        <base-block-title
          :title="$t('company')"
          :sub-title="companyList.length">
        </base-block-title>
      </div>
      <div class="content">
        <company-item
          v-for="item in companyList"
          :key="item.id"
          :company="item"
          :active="item.id === (currentCompany || {}).id"
          @click.native="currentCompany = item">
        </company-item>
      </div>
    </div>
    <div class="right">
      <div class="title-button">
        <base-block-title
          :title="$t('user')"
          :sub-title="currentCompany.companyName"
          :sub-title2="roleList.length"
          :sub-title3="companyStatus"
          >
        </base-block-title>
        <div class="button-block">
          <!--增加角色-->
          <el-button
            v-if="hasPermission($permissionFunctionCode.ADD)"
            type="primary"
            plain
            round
            @click="addRowData">
            {{ $t('add') }}
          </el-button>
        </div>
      </div>
      <div class="table-block">
        <el-table
          v-loading="tableLoading"
          :data="roleList"
          row-key="id"
          default-expand-all
          height="100%"
          :default-sort="{prop:'sort'}">
          <base-no-data slot="empty"></base-no-data>
          <el-table-column
            type="index"
            :label="$t('serialNum')"
            align="center"
            :width="50">
          </el-table-column>
          <el-table-column
            prop="name"
            align="center"
            :label="$t('roleName')">
          </el-table-column>
          <el-table-column
            prop="code"
            align="center"
            :label="$t('code')">
          </el-table-column>
          <el-table-column
            prop="description"
            align="center"
            :label="$t('desc')">
          </el-table-column>
          <el-table-column
            prop="enable"
            align="center"
            :label="$t('enable')">
            <template slot-scope="scope">
              <div class="status" :class="scope.row.enable?'enabled-status':'disabled-status'">
                <span></span>
                <span>{{ scope.row.enable ? `${$t('enable')}` : `${$t('disabled')}` }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="createTime"
            align="center"
            :label="$t('createTime')">
          </el-table-column>
          <el-table-column
            align="center"
            fixed="right"
            label="Operate"
            width="180">
            <template slot-scope="scope">
              <!--修改-->
              <el-button
                v-if="hasPermission($permissionFunctionCode.EDIT)"
                type="text"

                :disabled="defaultRoleCodeList.includes(scope.row.code)"
                @click="editRowData(scope.row)">
                {{ $t('edit') }}
              </el-button>
              <!--删除-->
              <base-button-delete
                v-if="hasPermission($permissionFunctionCode.DELETE)"
                :disabled="defaultRoleCodeList.includes(scope.row.code)"
                @confirm="deleteRole(scope.row, getRolesByCompany)">
              </base-button-delete>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <base-form-add-or-edit
      v-if="addDialogVisible"
      :visible="addDialogVisible"
      :url-save="saveUrl"
      :url-update="saveUrl"
      :fields="fields"
      labelWidthCn="100"
      labelWidthEn="200"
      @closed="addDialogVisible=false"
      @submit-success="getRolesByCompany">
    </base-form-add-or-edit>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import baseMixin from '@/mixins/base-mixin'
import baseTableMixin from '@/mixins/base-table-mixin'
import { getInternalRoleCodes } from '@/services/modules/comm.js'

export default {
  name: 'RoleManager',
  mixins: [baseMixin, baseTableMixin],
  data () {
    return {
      fields: [
        {
          fieldName: 'orgId',
          fieldType: 'invisible'
        },
        {
          fieldName: 'name',
          label: 'roleName',
          rules: 'required'
        },
        {
          fieldName: 'code',
          fieldType: 'invisible',
          label: 'code',
          formConfig:
            {
              type: 'select',
              clearable: true
            }
        },
        {
          fieldName: 'description',
          label: 'desc'
        },
        {
          fieldName: 'enable',
          fieldType: 'Boolean',
          default: true,
          formConfig:
            {
              type: 'switch'
            }
        },
        {
          fieldName: 'internal',
          fieldType: 'invisible',
          default: false
        }
      ],
      companyList: [],
      roleList: [],
      currentCompany: {},
      privilegesVisible: false,
      internalRoleCodes: []
    }
  },

  computed: {
    ...mapState(['permissions']),
    defaultRoleCodeList () {
      return Object.keys(this.$roleCode).map(key => this.$roleCode[key])
    },
    hasSysAdminPermission () {
      return this.permissions.hasSysAdminPermission
    },
    companyStatus () {
      return this.getCompanyStatusText(this.currentCompany)
    }
  },

  created () {
    this.saveUrl = this.$apis.saveRole
    this.deleteUrl = this.$apis.deleteRole
    this.getCompanies()

    this.getInternalRoleCodes().then(res => {
      if (res?.code === 1000) {
        this.internalRoleCodes = res.data ?? []
      }
    })
  },
  methods: {
    getInternalRoleCodes,
    getCompanies () {
      /**
       * 获取当前用户的企业列表
       */
      const assignCurrentCompany = async () => {
        if(this.companyList.length <= 0) return
        this.currentCompany = this.companyList[0]
      }

      return new Promise((resolve, reject) => {
        this.fullScreenLoading = true
        if(this.hasSysAdminPermission) {
          this.$request.get({
            url: `${this.$apis.companyBaseUrl}?pageNumber=1&pageSize=99999&offline=false`,
          }).then(res => {
            if (res?.code === 1000) {
              this.companyList = res?.data?.records || []
              this.companyList.unshift({
                id: null,
                companyName: this.$t('builtSystemOrg'),
                enable: true,
                active: 1
              })
              assignCurrentCompany().then(() => {
                this.fullScreenLoading = false
                resolve()
              })
            }
          })
        }
        else {
          const { currentCompany: globalCurrentCompany } = this.$store.state
          this.companyList = globalCurrentCompany ? [globalCurrentCompany] : []
          assignCurrentCompany().then(() => {
            this.fullScreenLoading = false
            resolve()
          })
        }
      })
    },
    getRolesByCompany (companyId = this.currentCompany.id) {
      /**
       * 获取企业下面的所有角色
       */
      this.tableLoading = true
      this.$request.get({
        url: `${this.$apis.roleBaseUrl}?pageNumber=1&pageSize=99999` + (companyId ? `&organizationId=${companyId}` : `&internal=true`),
      }).then(res => {
        if (res?.code === 1000) {
          this.roleList = res.data.records
          this.total = res.data.total
        }
      }).finally(() => {
        this.tableLoading = false
      })
    },

    addRowData () {
      /**
       * 增加角色
       */

      this.fields = this.$options.data().fields
      //this.fields[0].default = this.currentCompany.id
      const organizationFieldIndex = this.fields.findIndex(field => field.fieldName === 'orgId')

      if(organizationFieldIndex === -1) {
        this.$message.error('Organization field does not exist.')
      }

      this.fields[organizationFieldIndex].default = this.currentCompany.id
      this.setFieldSettingForSysAdmin(this.fields)
      this.addDialogVisible = true
    },

    editRowData (row) {

      this.currentRow = this.$_.cloneDeep(row)
      this.fields = this.$options.data().fields
      this.fields.push(
        {
          fieldName: 'id',
          fieldType: 'invisible'
        }
      )
      this.fields.forEach(field => {
        field.default = this.currentRow[field.fieldName]
      })

      this.setFieldSettingForSysAdmin(this.fields)
      this.addDialogVisible = true
    },

    setFieldSettingForSysAdmin (fields) {
        if(!this.hasSysAdminPermission) return

        const codeFieldIndex = fields.findIndex(field => field.fieldName === 'code')
        if(codeFieldIndex !== -1) {
          fields[codeFieldIndex].fieldType = undefined
          fields[codeFieldIndex].formConfig.options = this.internalRoleCodes.map(code => ({ value: code}))
        }

        if(this.currentCompany.id === null) {
          fields[codeFieldIndex].rules = 'required'

          const internalFieldIndex = fields.findIndex(field => field.fieldName === 'internal')
          if(internalFieldIndex !== -1) {
            fields[internalFieldIndex].default = true
          }
        }
    },

    deleteCallBack (message) {
      if (message === 'success') {
        this.getRolesByCompany(this.currentCompany.id)
      }
    },
    deleteRole (item, getRolesByCompany) {
      /**
       * 删除角色
       */
      this.deleteUrl = this.$apis.deleteRole
      this.deleteRowDataMixin(item, this.deleteCallBack)
    }
  },

  watch: {
    'currentCompany.id': {
      handler (value) {
        if (value) {
          this.getRolesByCompany(value)
        }
        else if (value === null && this.hasSysAdminPermission) {
          this.getRolesByCompany(null)
        }
      },
      immediate: true
    },
    '$route' (to) {
      const { companyId } = to.params
      this.currentCompany = this.companyList.find(item => item.id === companyId) || {}
    }
  }
}
</script>

<style lang="scss" scoped>
@import "index";
</style>
